import React, { useState } from 'react';
import { useLocation, useNavigate, useNavigation, useNavigationType } from 'react-router-dom';
import { useAuth } from '../contexts/authContext'; // Update the import path
import Swal from 'sweetalert2';

const PasswordRest = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const { resetPassword } = useAuth();

    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const userId = query.get('userId');
    const secret = query.get('secret');

    const handleChange = (e) => {
        setMessage('')
        const { name, value } = e.target;
        if (name === 'newPassword') {
            setNewPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const validatePassword = (password) => {
        return password.length >= 8
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(newPassword)) {
            return setMessage('كلمة المرور غير صالحة.');
        }
        if (newPassword !== confirmPassword) {
            return setMessage('كلمتا المرور غير متطابقتين.');
        }
        try {
            await resetPassword(userId, secret, newPassword);
        } catch (error) {
            console.log(error);

            setMessage('حدث خطأ أثناء إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="relative flex flex-col gap-8 w-full lg:w-[447px] justify-center lg:h-[575px] text-right p-4">
                <div className="flex flex-col gap-8 w-full self-center lg:w-[447px] lg:h-[575px] text-right p-4">
                    <div className="flex flex-col gap-2 lg:gap-[21px]">
                        <h2 className="font-bold text-2xl lg:text-[28px] text-[#2F3367]">
                            إعادة تعيين كلمة المرور
                        </h2>
                        <p className="text-[#303468] text-sm">
                            الرجاء إدخال كلمة المرور الجديدة وتأكيدها.
                        </p>
                    </div>

                    <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
                        <input
                            type="password"
                            name='newPassword'
                            value={newPassword}
                            onChange={handleChange}
                            placeholder="أدخل كلمة المرور الجديدة"
                            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                        />
                    </div>

                    <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
                        <input
                            type="password"
                            name='confirmPassword'
                            value={confirmPassword}
                            onChange={handleChange}
                            placeholder="تأكيد كلمة المرور الجديدة"
                            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                        />
                    </div>

                    {message && (
                        <p className='text-sm text-right  text-red-500'>
                            {message}
                        </p>
                    )}

                    <button
                        type="submit"
                        className="flex justify-center items-center shadow-xl border-2 w-full p-2 hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
                    >
                        <p className="pl-3 text-xl lg:text-2xl font-semibold">
                            تحديث كلمة المرور
                        </p>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PasswordRest;
