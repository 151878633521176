
import React, { useEffect, useState } from 'react'
import { FaRegClock, FaStar } from 'react-icons/fa6';
import NavBar from '../../componets/NavBar';
import Footer from '../../componets/Footer';
import { useAuth } from '../../contexts/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { grade_submission } from '../../services/api';
import Swal from 'sweetalert2';

const GradeSubmission = () => {

    const [isUploading, setUploading] = useState(false)
    const [hover, setHover] = useState(0);
    const [rating, setRating] = useState(0);
    const [notes, setNotes] = useState('');
    const [data, setData] = useState([])
    const [warns, setWarns] = useState({
        rate: false,
        notes: false
    })

    const { user } = useAuth();
    const { state } = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if (!state) {
            return navigate('/lawyer');
        }
        setData(state.item)

    }, [state, navigate])

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);

        if (isNaN(date.getTime())) {
            console.error('Invalid date');
            return '...';
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
        setWarns(prevWarns => ({ ...prevWarns, notes: false }));
    }

    const handleStarClick = (star) => {
        setRating(star)
        return setWarns(prevWarns => ({ ...prevWarns, rate: false }));

    }

    const handleGrade = () => {
        if (!notes && !rating)
            setWarns(prevWarns => ({ ...prevWarns, rate: true, notes: true }));
        if (!notes)
            return setWarns(prevWarns => ({ ...prevWarns, notes: true }));
        if (!rating)
            return setWarns(prevWarns => ({ ...prevWarns, rate: true }));

        setUploading(true)
        const { trainee_id, trainee_name } = data

        grade_submission({
            submission_id: data.$id,
            trainee_id,
            trainee_name,
            trainer_id: user.$id,
            trainer_name: user.name,
            rating,
            notes,
        }).then(() =>
            Swal.fire({
                text: "تم رفع التقييم بنجاح",
                icon: "success"
            }).then(_ => navigate("/lawyer")))
    }
    return (
        <div className='max-w-7xl flex flex-col justify-between relative mx-auto min-h-screen py-3 '>
            <NavBar theme={'light'} />
            <div className='text-right rounded-10 p-5 md:p-10 bg-darkBlue h-full flex-grow '
                style={{ direction: "rtl" }}
            >
                <div className="grid grid-cols-1 py-10 md:grid-cols-2 gap-2 text-white text-3xl"
                >
                    <p className='font-bold'> المتدرب: <span className='font-semibold'>{data.trainee_name}</span></p>
                    <p className='font-bold'>  القضية: <span className='font-semibold'>{data.submission_type}</span></p>
                    <p className='font-bold'> تاريخ الإرسال
                        : <span className='font-semibold'> {formatDate(data.$updatedAt)}</span> </p>

                </div>

                <div className="bg-white w-full h-full p-3 flex flex-col gap-14 text-darkBlue text-3xl rounded-10"

                >
                    <div className="flex flex-col gap-5">
                        <p>تفاصيل الرد:</p>
                        <audio controls src={data.voice_recording_link} >
                            لا يدعم متصفحك تشغيل الصوت.
                            {/* <source type="audio/mpeg" /> */}
                        </audio>
                    </div>
                    <div className="flex flex-col gap-5">
                        <p>تقييم الرد:</p>
                        <div className="bg-[#37343c] rounded-full py-3 px-5 w-fit flex gap-2 md:gap-5 "

                        >
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`size-10 cursor-pointer ${star <= (hover || rating) ? 'text-yellow-400' : 'text-white'}`}
                                    onClick={() => handleStarClick(star)}
                                    onMouseEnter={() => setHover(star)}
                                    onMouseLeave={() => setHover(0)}
                                />
                            ))}
                        </div>
                        {warns.rate && <p className='text-darkRed text-xl'>لم تقم بتحديد التقييم</p>}
                    </div>
                    <div className="flex flex-col gap-5 flex-grow">
                        <p>
                            ملاحظات للمتدرب:
                        </p>
                        <textarea
                            onChange={handleNotesChange}
                            rows="6"
                            style={{ resize: "none" }}
                            placeholder="ابدأ بالكتابة..."
                            className="border-2 border-primary bg-gray-200 rounded-10 p-5 size-full flex-grow align-top text-wrap outline-none text-right"
                        ></textarea>
                        {warns.notes && <p className='text-darkRed text-xl'>لم تكتب ملاحظات للمتدرب</p>}
                    </div>
                    <div className="my-3 relative flex justify-center">
                        <button
                            onClick={handleGrade}
                            disabled={isUploading}
                            className={`bg-darkBlue py-4 text-center text-white rounded-full w-3/4 mx-auto   ${isUploading ? 'bg-darkBlue/80' : 'hover:bg-hoverPrimary'}`}>
                            {isUploading ? 'جاري التحميل...' : 'إرسال'}
                        </button>
                    </div>
                </div>
            </div>
            <Footer theme={'light'} />
        </div>
    )
}
export default GradeSubmission;
