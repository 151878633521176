import React, { useEffect, useState } from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  // Hooks and Functions --

  const [errorMsg, setErrorMsg] = useState("");
  const { loginUser, user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (user) {
      navigate('/')
    }
    console.log(user);
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await loginUser(formData); // Attempt to log in with the provided credentials
      } catch (error) {
        setErrorMsg("Failed to log in. Please check your credentials."); // Handle login errors
      }
    }

    console.log(formData);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMsg("Invalid email format");
      console.log("foo");
      return false;
    }

    console.log("foo");
    return true;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative flex flex-col gap-8 w-full lg:w-[447px] justify-center lg:h-[575px] text-right p-4">
        <div className="justify-center items-center">
          <div className=" flex flex-col gap-8 w-full self-center lg:w-[447px] lg:h-[575px] text-right p-4">
            <div className="flex flex-col gap-2 lg:gap-[21px]">
              <h2 className="font-bold text-2xl lg:text-[28px] text-[#2F3367]">
                {" "}
                تسجيل الدخول
              </h2>
              <p className="text-[#303468] text-sm">
                الرجاء ادخال المعلومات الشخصية
              </p>
            </div>

            <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
              <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
                <MdOutlineAlternateEmail />
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="أيميل"
                className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                required
              />
            </div>
            <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
              <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
                <RiLockPasswordLine />
              </div>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="أدخل كلمة المرور"
                className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                required
              />
            </div>
            {errorMsg && (
              <p className="text-red-500 text-sm text-right">{errorMsg}</p>
            )}

            <button
              onClick={handleSubmit}
              type="submit"
              className="flex justify-center items-center shadow-xl border-2 w-full lg:w-[176px] h-12 lg:h-[60px] hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
            >
              <p className="pl-3 text-xl lg:text-2xl font-semibold">تسجيل</p>
            </button>

            <div className="flex justify-between w-full">
              <Link to={"/register"}>
                <a
                  className="font-semibold text-base text-primary hover:text-pink"
                  href="/"
                >
                  انشاء حساب
                </a>
              </Link>
              <p className="font-medium text-base">ليس لديك حساب؟</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
